<template>
  <SoloBaseSelect
    :searchMethod="searchMethod"
    :modelValue="modelValue"
    @update:modelValue="updateEmit"
  />
</template>
<script>
import uniqBy from 'lodash/uniqBy'
import SoloBaseSelect from '@/components/selectors/solo-selector'
import { getAdminStaffList } from '@/domains/base-baoer'

/**
 * 单选远程搜索框用例 搜wscn admin员工库
 * @param vModel string number object 若string、number为真需要手动指定options
 * @param searchMethod 编写搜索函数
 * @param keymap 指定keymap
 */
function searchMethod(query, ctx) {
  if (/\S/.test(query)) {
    ctx.loading.value = true
    getAdminStaffList({ email: query })
      .then((data) => {
        if (data.items && data.items.length) {
          const options = data.items.map((uu) => {
            return {
              label: `${uu.display_name || uu.email}`,
              value: `${uu.user_id}__${uu.display_name || uu.email}`
            }
          })
          ctx.options.value = uniqBy(options, 'value')
          return
        }
        console.log('no staff!')
        ctx.options.value = []
      })
      .finally(() => {
        ctx.loading.value = false
      })
  }
}
export default {
  name: 'StockSelect',
  components: { SoloBaseSelect },
  props: {
    modelValue: { type: String, default: '' }
  },
  data() {
    this.searchMethod = searchMethod
    return {}
  },
  methods: {
    updateEmit(ev) {
      this.$emit('update:modelValue', ev)
    }
  }
}
</script>