<template>
  <div class="page-common page-admin-auth">
    <div class="page-params">
      <el-input
        clearable
        class="param-item"
        placeholder="搜权限"
        @clear="initAuthList"
        v-model="params.q"
      >
        <template #append>
          <el-button @click="initAuthList" icon="el-icon-search" type="primary"></el-button>
        </template>
      </el-input>
      <StaffSelect class="param-item" placeholder="筛选管理员" v-model="highLightManager" />
      <span style="font-size: 12px">删除、拖拽操作保存后生效</span>&nbsp;&nbsp;
      <el-button
        class="param-item"
        type="primary"
        icon="el-icon-s-promotion"
        @click="saveAuthTree"
      >保存</el-button>
      <el-button
        class="param-item"
        icon="el-icon-plus"
        type="success"
        @click="openAddModal(null)"
      >创建根权限</el-button>
    </div>
    <div class="auth-desc-head">
      <span class="desc-head-var">权限名称</span>
      <span style="flex-basis: 320px">路由</span>
      <span>授权人数</span>
      <span>状态</span>
      <span>操作</span>
    </div>
    <el-tree
      v-if="!rerendering"
      draggable
      node-key="id"
      default-expand-all
      v-loading="tableLoading"
      element-loading-spinner="el-icon-loading"
      :data="authListTree"
      :props="defaultProps"
      :expand-on-click-node="false"
      @node-drop="handleDrop"
    >
      <template #default="{ data }">
        <div class="custom-tree-node" :class="highLightAuth(data, highLightId)">
          <span class="tree-node-var">
            {{data.name}}
            <el-popover placement="top-start" title trigger="hover" content="添加子权限">
              <template #reference>
                <i class="el-icon-circle-plus-outline" @click="openAddModal(data)"></i>
              </template>
            </el-popover>
          </span>&nbsp;&nbsp;
          <span class="tree-node-col" style="flex-basis: 320px">{{data.route}}</span>
          <span class="tree-node-col">
            <a
              href="javascript:;"
              style="font-size: 14px;
              font-weight: 500"
              @click="checkAuthUser(data.id)"
            >{{data.user_count}} 人</a>
          </span>
          <span class="tree-node-col">
            <el-tag v-if="data.is_deleted" type="danger">无效</el-tag>
            <el-tag v-else type="success">生效</el-tag>
          </span>
          <span class="tree-node-col">
            <el-button type="primary" size="mini" @click="editSomeAuth(data)">编辑</el-button>
            <el-popconfirm @confirm="deleteSomeAuth(data)" title="确认删除操作？">
              <template #reference>
                <el-button type="danger" size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </span>
        </div>
      </template>
    </el-tree>
    <el-dialog
      center
      title="编辑权限"
      custom-class="medium-modal"
      v-model="editModal.visible"
      :close-on-click-modal="false"
      @close="editModal.visible = false"
    >
      <el-form label-width="100px" :model="editModal">
        <el-form-item label="权限名称">
          <el-input v-model.trim="editModal.name"></el-input>
        </el-form-item>
        <el-form-item label="权限路由">
          <el-input v-model.trim="editModal.route"></el-input>
        </el-form-item>
        <el-form-item label="管理员">
          <el-tag
            style="margin: 0 6px 6px 0"
            v-for="man in editModal.managers"
            :key="man.user_id"
            closable
            @close="removeManager('edit', man.user_id)"
          >{{man.display_name || man.email}}</el-tag>
          <div>
            <StaffSelect
              style="width: 280px"
              placeholder="搜索内部员工(姓名、邮箱)"
              v-model="editModal.tempStaff"
            />&nbsp;&nbsp;
            <el-button
              :disabled="!editModal.tempStaff"
              type="primary"
              @click="addTempStaffToManager('edit')"
            >添加</el-button>
          </div>
        </el-form-item>
        <el-form-item label="是否生效">
          <el-checkbox v-model="editModal.is_effective"></el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            :disabled="!editModal.name || !editModal.route"
            type="primary"
            @click="editModalConfirm"
          >保存到 权 限 池</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      center
      title="添加权限"
      custom-class="medium-modal"
      v-model="addModal.visible"
      :close-on-click-modal="false"
      @close="addModal.visible = false"
    >
      <el-form label-width="100px" :model="addModal">
        <el-form-item label="权限名称">
          <el-input placeholder="名称唯一，限20个字符" v-model.trim="addModal.name"></el-input>
        </el-form-item>
        <el-form-item label="权限路由">
          <el-input placeholder="路由唯一，限50个字符" v-model.trim="addModal.route"></el-input>
          <div style="color: #e50;
          font-weight: 600">
            若路由为动态路由 则一般格式为
            <code>/a/b/:id</code> 具体可参照前端代码
          </div>
        </el-form-item>
        <el-form-item label="管理员">
          <el-tag
            closable
            style="margin: 0 6px 6px 0"
            v-for="man in addModal.managers"
            :key="man.user_id"
            @close="removeManager('add', man.user_id)"
          >{{man.display_name}}</el-tag>
          <div>
            &nbsp;
            <StaffSelect
              style="width: 280px"
              placeholder="搜索内部员工(姓名、邮箱)"
              v-model="addModal.tempStaff"
            />&nbsp;
            <el-button
              :disabled="!addModal.tempStaff"
              type="primary"
              @click="addTempStaffToManager('add')"
            >添加</el-button>
          </div>
        </el-form-item>
        <el-form-item label="是否生效">
          <el-checkbox v-model="addModal.is_effective"></el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            :disabled="!addModal.name || !addModal.route"
            type="primary"
            @click="addModalConfirm"
          >添加到 权 限 池</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="授权用户"
      custom-class="medium-modal auth-user-modal"
      v-model="authUserModal.visible"
      @close="authUserModal.visible = false"
      :close-on-click-modal="false"
    >
      <div style="margin-bottom: 10px">
        <StaffSelect
          style="width: 280px"
          placeholder="搜索内部员工(姓名、邮箱)"
          v-model="authUserModal.tempStaff"
        />&nbsp;&nbsp;
        <el-button
          type="primary"
          :disabled="!authUserModal.tempStaff"
          @click="addTempStaffToAuth"
        >添加</el-button>&nbsp;&nbsp;
        <span>保存后生效</span>
      </div>
      <div class="auth-user-box">
        <el-tag
          closable
          class="auth-user-item"
          v-for="(user, idx) in authUserList"
          :key="user.user_id"
          @close="authUserList.splice(idx, 1)"
        >{{user.display_name}}</el-tag>
      </div>
      <template #footer></template>
      <span class="dialog-footer">
        <el-button type="primary" @click="authUserModalConfirm">保 存</el-button>
      </span>
    </el-dialog>
    <PageOfTop />
  </div>
</template>
<script>
import uniqBy from 'lodash/uniqBy'
import StaffSelect from '@/views/admin-settings/admin-staff-select.vue'
import PageOfTop from '@/components/base/page-of-top'
import {
  getAdminAuthList,
  addAdminAuth,
  updateStaffAuthTree,
  getAdminStaffList,
  updateSomeAuthStaffList
} from '@/domains/base-baoer'

// 获取到包含当前auth的那个children数组
function getParentItems(tree, { path, id }) {
  const patharr = path ? path.split(',') : []
  if (!path) return tree
  let temp = null
  for (let idx = 0; idx < patharr.length; idx++) {
    const theid = patharr[idx]
    if (temp && temp.length) {
      temp = temp.find((v) => v.id === Number(theid)).items
    } else {
      temp = tree.find((v) => v.id === Number(theid)).items
    }
  }
  return temp
}

function checkManagerForSomeAuth(auth, my_user_id) {
  if (!auth.managers || !auth.managers.length) return true
  if (auth.managers.find((v) => v.user_id === Number(my_user_id))) return true
  return false
}

/**
 * 根据管理员ID高亮权限
 */
function highLightAuth({ managers = [] }, highLightId) {
  if (managers.find((v) => String(v.user_id) === highLightId)) return 'high-light'
  return ''
}

const { POOL = {} } = window.ROUTER_AUTH || {}

export default {
  name: 'AdminAuth',
  components: { PageOfTop, StaffSelect },
  data() {
    this.keymap = {
      label: ''
    }
    return {
      rerendering: false,
      tableLoading: false,
      highLightManager: '',
      params: { q: '' },
      authListTree: [],
      defaultProps: {
        children: 'items',
        label: 'name'
      },
      addModal: {
        visible: false,
        route: '',
        name: '',
        is_effective: false,
        type: 'root',
        parent_id: 0,
        managers: [],
        tempStaff: ''
        // is_deleted: false
      },
      editModal: {
        id: undefined,
        visible: false,
        route: '',
        name: '',
        is_effective: false,
        managers: [],
        tempStaff: ''
      },
      authUserModal: {
        visible: false,
        id: '',
        tempStaff: ''
      },
      authUserList: []
      // authUserMap: {}
    }
  },
  computed: {
    my_user_id() {
      return this.$store.state.user.userInfo.uid
    },
    highLightId() {
      const highLightId = this.highLightManager
      if (!highLightId) return ''
      const [user_id, _] = highLightId.split('__')
      console.log('highLightId', user_id)
      return user_id
    }
  },
  mounted() {
    this.initAuthList()
  },
  methods: {
    highLightAuth,
    rerender() {
      // element-plus 树形组件存在更新问题 暂未解决
      this.rerendering = true
      this.$nextTick(() => {
        this.rerendering = false
      })
    },
    initAuthList() {
      this.tableLoading = true
      getAdminAuthList(this.params)
        .then((data) => {
          if (data.items && data.items.length) {
            this.authListTree = data.items
            this.rerender()
          } else {
            this.authListTree = []
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleDrop(node1, node2, type, ev) {
      if (!this.hadMind) {
        this.$alert('拖拽结构树点击保存后生效', '操作提示', {
          confirmButtonText: '确定'
        })
        this.hadMind = true
      }
    },
    saveAuthTree() {
      updateStaffAuthTree({ items: this.authListTree })
        .then(() => {
          this.$message.success('已保存')
          this.initAuthList()
        })
        .catch((err) => {
          this.$message.error(err.message)
          this.initAuthList()
        })
    },
    openAddModal(data) {
      Object.assign(this.addModal, {
        is_effective: false,
        name: '',
        route: '',
        visible: true,
        managers: [],
        tempStaff: ''
      })
      if (data) {
        this.addModal.type = 'sub'
        this.addModal.parent_id = data.id
      } else {
        this.addModal.type = 'root'
        this.addModal.parent_id = 0
      }
    },
    addTempStaffToManager(type) {
      if (type === 'add') {
        const { tempStaff, managers } = this.addModal
        const [user_id, display_name] = tempStaff.split('__')
        console.log({ user_id, display_name })
        if (this.my_user_id === Number(user_id)) {
          this.$message.error('已默认添加创建者为管理员')
          return
        }
        if (managers.find((v) => v.user_id == user_id)) {
          this.$message.error('请勿重复添加')
          return
        }
        this.addModal.managers = [...managers, { user_id, display_name }]
        this.$nextTick(() => {
          this.addModal.tempStaff = ''
        })
      } else {
        const { tempStaff, managers } = this.editModal
        const [user_id, display_name] = tempStaff.split('__')
        if (managers.find((v) => v.user_id == user_id)) {
          this.$message.error('请勿重复添加')
          return
        }
        this.editModal.managers = [...managers, { user_id, display_name }]
        this.$nextTick(() => {
          this.editModal.tempStaff = ''
        })
      }
    },
    removeManager(type, user_id) {
      if (type === 'add') {
        this.addModal.managers = this.addModal.managers.filter((v) => v.user_id !== Number(user_id))
      } else {
        this.editModal.managers = this.editModal.managers.filter((v) => v.user_id !== Number(user_id))
      }
    },
    addModalConfirm() {
      const { addModal } = this
      const body = {
        route: addModal.route,
        name: addModal.name,
        parent_id: addModal.parent_id,
        is_deleted: !addModal.is_effective,
        manager_ids: addModal.managers.map((v) => Number(v.user_id))
      }
      if (POOL[addModal.route]) {
        this.$message.error('请检查路由是否于现有的节点路由重复')
        return
      }
      addAdminAuth(body)
        .then(() => {
          this.$message.success('已添加')
          this.addModal = {
            ...this.addModal,
            visible: false,
            route: '',
            name: '',
            is_effective: true,
            type: 'root',
            parent_id: 0
          }
          this.initAuthList()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    editSomeAuth(auth) {
      // 这里不需要找到是那个节点的索引 直接通过引用更改即可
      // const { path, depth, order } = auth
      // const patharr = path ? path.split(',') : []
      // if (patharr.length) {
      // }
      this.tempAuth = auth
      Object.assign(this.editModal, {
        visible: true,
        id: auth.id,
        route: auth.route,
        name: auth.name,
        is_effective: !auth.is_deleted,
        tempStaff: '',
        managers: uniqBy(auth.managers, 'user_id')
      })
    },
    editModalConfirm() {
      if (!checkManagerForSomeAuth(this.tempAuth, this.my_user_id)) {
        this.$message.error('您不能操作该权限')
        return
      }
      const { id, route, name, is_effective, managers } = this.editModal
      const body = {
        id,
        route,
        name,
        is_deleted: !is_effective,
        manager_ids: managers.map((v) => Number(v.user_id))
      }
      addAdminAuth(body)
        .then(() => {
          this.$message.success('已保存')
          Object.assign(this.editModal, {
            visible: false,
            route: '',
            name: '',
            is_effective: true
          })
          this.initAuthList()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    deleteSomeAuth(auth) {
      if (!checkManagerForSomeAuth(auth, this.my_user_id)) {
        this.$message.error('您不能操作该权限')
        return
      }
      /**
       * 删除、拖拽后均不立即生效、保存后生效
       */
      const { authListTree } = this
      const items = getParentItems(authListTree, auth)
      items.forEach((v, i) => {
        if (v.id === auth.id) {
          items.splice(i, 1)
        }
      })
      this.rerender()
      // this.saveAuthTree()
    },
    checkAuthUser(id) {
      this.authUserModal.visible = true
      this.authUserModal.id = id
      this.authUserList = []
      getAdminStaffList({ permission_id: id, limit: 1000 }).then((data) => {
        if (data.items && data.items.length) {
          const temp = {}
          temp[id] = data.items.length
          // this.authUserMap = Object.assign(temp, this.authUserMap)
          this.authUserList = data.items.map((v) => {
            return {
              user_id: v.user_id,
              email: v.email,
              display_name: v.display_name || v.email
            }
          })
        }
      })
    },
    addTempStaffToAuth() {
      const { tempStaff } = this.authUserModal
      const [user_id, display_name] = tempStaff.split('__')
      if (this.authUserList.find((v) => v.user_id == user_id)) {
        this.$message.warning('请勿重复添加')
        return
      }
      this.authUserList.push({ user_id, display_name })
      this.$nextTick(() => {
        this.authUserModal.tempStaff = ''
      })
    },
    authUserModalConfirm() {
      const { id } = this.authUserModal
      const user_ids = this.authUserList.map((v) => Number(v.user_id))
      const body = {
        permission_id: Number(id),
        user_ids
      }
      updateSomeAuthStaffList(body)
        .then(() => {
          this.$message.success('已保存')
          this.authUserModal.visible = false
          this.authUserModal.tempStaff = ''
          this.initAuthList()
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
          this.authUserModal.tempStaff = ''
        })
    }
  }
}
</script>
<style lang="scss">
.page-admin-auth {
  position: relative;
  .el-tree {
    padding: 8px;
    .el-tree-node__content {
      height: 36px;
      &:hover {
        background: #f4f4f4;
      }
    }
    .custom-tree-node {
      align-items: center;
      display: flex;
      height: 36px;
      justify-content: space-between;
      padding-right: 8px;
      width: 100%;
      &.high-light {
        background: #ffe097;
      }
      .tree-node-col {
        flex-basis: 130px;
        text-align: left;
      }
      .tree-node-var {
        color: #333;
        flex-grow: 1;
        flex-shrink: 0;
        font-weight: 600;
        margin-right: auto;
      }
    }
  }
  .auth-desc-head {
    align-items: center;
    background: #f4f4f4;
    border-bottom: 1px solid #fff;
    color: #999;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 36px;
    justify-content: space-between;
    padding: 0 16px 0 40px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9;
    span {
      flex-basis: 130px;
      flex-grow: 0;
      text-align: left;
      &.desc-head-var {
        flex-grow: 1;
        text-align: left;
      }
    }
  }
  @media (min-width: 1560px) {
    .auth-desc-head {
      span {
        flex-basis: 200px;
      }
    }
    .el-tree {
      .custom-tree-node {
        .tree-node-col {
          flex-basis: 200px;
        }
      }
    }
  }
  .auth-user-modal {
    .auth-user-box {
      max-height: 600px;
      overflow-y: scroll;
      width: 100%;
    }
    .auth-user-item {
      font-size: 14px;
      margin: 0 10px 8px 0;
    }
  }
}
</style>
